import React from 'react';
import './PaginationControls.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faAnglesLeft, 
    faAngleLeft, 
    faAngleRight, 
    faAnglesRight 
} from '@fortawesome/free-solid-svg-icons';

const PaginationControls = ({ currentPage, totalPages, onPageChange, position }) => {
    const className = `pagination-controls ${position}`;
    
    return (
        <div className={className}>
            <button 
                onClick={() => onPageChange(1)} 
                disabled={currentPage === 1}
                aria-label="First page"
            >
                <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            <button 
                onClick={() => onPageChange(currentPage - 1)} 
                disabled={currentPage === 1}
                aria-label="Previous page"
            >
                <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button 
                onClick={() => onPageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
                aria-label="Next page"
            >
                <FontAwesomeIcon icon={faAngleRight} />
            </button>
            <button 
                onClick={() => onPageChange(totalPages)} 
                disabled={currentPage === totalPages}
                aria-label="Last page"
            >
                <FontAwesomeIcon icon={faAnglesRight} />
            </button>
        </div>
    );
};

export default PaginationControls;

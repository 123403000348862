import React from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = () => (
  <div className="loading-indicator" role="alert" aria-busy="true">
    <div className="spinner" aria-hidden="true"></div>
    <h2 className="visually-hidden">Loading events...</h2>
  </div>
);

export default LoadingIndicator;

import React from 'react';
import './CardButton.css';

const CardButton = ({ onClick, href, children }) => {
    if (href) {
        return (
            <a 
                href={href} 
                target="_self"
                className="card-button"
                onClick={(e) => {
                    e.stopPropagation();
                    if (onClick) onClick(e);
                }}
                rel={null}
            >
                {children}
            </a>
        );
    }
    return (
        <button 
            className="card-button" 
            onClick={(e) => {
                e.stopPropagation();
                if (onClick) onClick(e);
            }}
        >
            {children}
        </button>
    );
};

export default CardButton;
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, type = 'website', event = null }) => {
    const baseTitle = 'StageDive Philly';
    const fullTitle = title ? `${title} - ${baseTitle}` : baseTitle;
    const defaultDescription = 'Discover and track local music events in Philadelphia. Find concerts, save events, and never miss a show.';
    return (
        <Helmet>
            <title>{fullTitle}</title>
            <meta name="description" content={description} />
            
            {/* OpenGraph tags */}
            <meta property="og:title" content={fullTitle} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content={type} />
            
            {/* If it's an event, add structured data */}
            {event && (
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'Event',
                        'name': event.event_name,
                        'startDate': event.date,
                        'location': {
                            '@type': 'Place',
                            'name': event.venue
                        },
                        'description': event.event_details || ''
                    })}
                </script>
            )}
        </Helmet>
    );
};

export default SEO; 
import React, { useState, useEffect, useMemo } from 'react';
import { useSupabase } from './SupabaseProvider.tsx';
import EventListDisplay from './EventListDisplay';
import VenueFilter from './VenueFilter';
import PaginationControls from './PaginationControls';
import LoadingIndicator from './LoadingIndicator';
import NoEventsFound from './NoEventsFound';
import './EventList.css';

const ITEMS_PER_PAGE = 50;

const EventList = ({ user, searchParams, setSearchParams, resetEventList, setResetEventList, eventId }) => {
    const supabase = useSupabase();
    const [allEvents, setAllEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState(localStorage.getItem('selectedVenue') || 'all');
    const [expandedEventId, setExpandedEventId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [savedEventIds, setSavedEventIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [eventIdMap, setEventIdMap] = useState({});

    useEffect(() => {
        if (resetEventList) {
            setSelectedVenue('all');
            localStorage.setItem('selectedVenue', 'all');
            setCurrentPage(1);
            setResetEventList(false);
        }
    }, [resetEventList, setResetEventList]);

    useEffect(() => {
        if (supabase) {
            fetchEvents();
            if (user) {
                fetchSavedEvents();
            }
        } else {
            console.log("Supabase client not available yet");
        }
    }, [supabase, user]);

    useEffect(() => {
        if (allEvents.length > 0) {
            applyFilters();
        }
    }, [allEvents, eventId, searchParams, selectedVenue, savedEventIds]);

    const fetchSavedEvents = async () => {
        try {
            const { data: savedEventsData, error: savedEventsError } = await supabase
                .from('saved_events')
                .select('event_id')
                .eq('user_id', user.id);

            if (savedEventsError) throw savedEventsError;

            console.log("Saved Events:", savedEventsData);

            const savedIds = savedEventsData.map(item => item.event_id.toString());
            setSavedEventIds(savedIds);

        } catch (error) {
            console.error("Error fetching saved events:", error);
        }
    };

    const fetchEvents = async () => {
        setIsLoading(true);
        try {
            console.log("Fetching events from Supabase...");
            if (!supabase) {
                throw new Error("Supabase client is not initialized");
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            // Convert to EST
            const todayEST = new Date(today.toLocaleString("en-US", {timeZone: "America/New_York"}));
            //console.log("Today in EST:", todayEST);
            
            const { data, error } = await supabase
                .from('events')
                .select()
                .gte('date', todayEST.toISOString().split('T')[0]) // Use only the date part in EST
                .order('date', { ascending: true });
    

            if (error) {
                throw error;
            }

            if (!data) {
                throw new Error("No data received from Supabase");
            }

            console.log(`Successfully fetched ${data.length} events from the server.`);
            if (data.length > 0) {
                console.log("Sample event:", data[0]);
            } else {
                console.log("No events found in the database.");
            }

            const newEventIdMap = {};
            const parsedData = data.map((item, index) => {
                const eventId = item.id.toString(); // Ensure id is a string
                newEventIdMap[eventId] = item.id;
                return {
                    ...item,
                    id: eventId,
                    Date: new Date(item.date).toISOString(),
                    'Event Name': item.event_name,
                    'Event Details': item.event_details,
                    Venue: item.venue,
                    'Ticket Link': item.ticket_link,
                    created_at: item.created_at
                };
            });

            setEventIdMap(newEventIdMap);
            setAllEvents(parsedData);

            // Don't set filteredEvents or events here, let the useEffect handle it
        } catch (error) {
            console.error("Error fetching events:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterFutureEvents = (events) => {
        const now = new Date();
        const todayEST = new Date(now.toLocaleString("en-US", {timeZone: "America/New_York"}));
        todayEST.setHours(0, 0, 0, 0);

        return events.filter(event => {
            const eventDate = new Date(event.Date);
            if (isNaN(eventDate.getTime())) {
                console.warn(`Invalid date for event: ${event.id}`);
                return false; // Filter out events with invalid dates
            }
            eventDate.setHours(0, 0, 0, 0);
            return eventDate >= todayEST;
        }).map(event => ({
            ...event,
            dateAdded: new Date(event['Date Added'] || new Date()) // Use current date if 'Date Added' is missing
        }));
    };

    const filterNewShows = (events, daysAgo = 7) => {
        const pastDate = new Date();
        pastDate.setDate(pastDate.getDate() - daysAgo);
        pastDate.setHours(0, 0, 0, 0); // Set to start of the day

        return events.filter(event => {
            const eventCreatedAt = new Date(event.created_at);
            return eventCreatedAt > pastDate;
        });
    };

    const handleVenueChange = (venue) => {
        setSelectedVenue(venue);
        localStorage.setItem('selectedVenue', venue);
        setCurrentPage(1);
        setSearchParams(null);
    };

    const handleEventExpand = (eventId) => {
        setExpandedEventId(eventId === expandedEventId ? null : eventId);
    };

    const totalPages = Math.ceil(filteredEvents.length / ITEMS_PER_PAGE);

    const handlePageChange = (newPage) => {
        const startIndex = (newPage - 1) * ITEMS_PER_PAGE;
        const paginatedEvents = filteredEvents.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        setEvents(paginatedEvents);
        setCurrentPage(newPage);
        setExpandedEventId(null);
        window.scrollTo(0, 0);
    };

    const handleSaveToggle = async (eventId, isChecked) => {
        if (!user) return;

        try {
            if (isChecked) {
                await supabase
                    .from('saved_events')
                    .insert({ user_id: user.id, event_id: eventId });
                setSavedEventIds(prev => [...prev, eventId]);
            } else {
                await supabase
                    .from('saved_events')
                    .delete()
                    .match({ user_id: user.id, event_id: eventId });
                setSavedEventIds(prev => prev.filter(id => id !== eventId));
            }

            // Trigger a re-filter if the current view is 'saved'
            if (selectedVenue === 'saved') {
                applyFilters();
            }

        } catch (error) {
            console.error("Error toggling saved event:", error);
        }
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === 'saved' && !user) {
            // If 'Saved Events' is selected but user is not logged in, don't do anything
            return;
        }
        // Handle the selection change
        // ...
    };

    const handleClearSearch = () => {
        setSearchParams(null);
        setSelectedVenue('all'); // Reset dropdown to 'All Shows'
        setFilteredEvents(allEvents);
        setEvents(allEvents.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
    };

    const isSearchActive = searchParams && Object.values(searchParams).some(value => value !== null && value !== '');

    const applySearchFilters = () => {
        let filteredData = allEvents;

        if (eventId) {
            filteredData = allEvents.filter(event => event.id === eventId);
        } else if (searchParams) {
            filteredData = allEvents.filter(event => {
                const eventDate = new Date(event.Date);
                eventDate.setHours(0, 0, 0, 0);

                const matchesText = searchParams.text ? 
                    ((event['Event Name']?.toLowerCase() || '').includes(searchParams.text.toLowerCase()) ||
                    (event['Event Details']?.toLowerCase() || '').includes(searchParams.text.toLowerCase())) : 
                    true;

                let matchesStartDate = true;
                if (searchParams.startDate) {
                    const startDate = new Date(searchParams.startDate);
                    startDate.setHours(0, 0, 0, 0);
                    matchesStartDate = eventDate >= startDate;
                }

                let matchesEndDate = true;
                if (searchParams.endDate) {
                    const endDate = new Date(searchParams.endDate);
                    endDate.setHours(23, 59, 59, 999);
                    matchesEndDate = eventDate <= endDate;
                }

                let matchesVenue = true;
                if (searchParams.venue && searchParams.venue !== 'all') {
                    matchesVenue = event.Venue === searchParams.venue;
                }
                
                return matchesText && matchesStartDate && matchesEndDate && matchesVenue;
            });
        }

        setFilteredEvents(filteredData);
        setEvents(filteredData.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
    };

    const applyFilters = () => {
        let newFilteredEvents = allEvents;

        // Apply eventId filter
        if (eventId) {
            newFilteredEvents = newFilteredEvents.filter(event => event.id === eventId);
            setExpandedEventId(eventId);
        } else {
            // Apply other filters only if eventId is not present
            if (selectedVenue === 'new-today') {
                newFilteredEvents = filterNewShows(newFilteredEvents, 0);
            } else if (selectedVenue === 'new-week') {
                newFilteredEvents = filterNewShows(newFilteredEvents, 7);
            } else if (selectedVenue === 'saved' && user) {
                newFilteredEvents = newFilteredEvents.filter(event => savedEventIds.includes(event.id));
            } else if (selectedVenue !== 'all') {
                newFilteredEvents = newFilteredEvents.filter(event => event.Venue === selectedVenue);
            }

            // Apply search filters
            if (searchParams) {
                newFilteredEvents = newFilteredEvents.filter(event => {
                    const eventDate = new Date(event.Date);
                    eventDate.setHours(0, 0, 0, 0);

                    const matchesText = searchParams.text ? 
                        ((event['Event Name']?.toLowerCase() || '').includes(searchParams.text.toLowerCase()) ||
                        (event['Event Details']?.toLowerCase() || '').includes(searchParams.text.toLowerCase())) : 
                        true;

                    let matchesStartDate = true;
                    if (searchParams.startDate) {
                        const startDate = new Date(searchParams.startDate);
                        startDate.setHours(0, 0, 0, 0);
                        matchesStartDate = eventDate >= startDate;
                    }

                    let matchesEndDate = true;
                    if (searchParams.endDate) {
                        const endDate = new Date(searchParams.endDate);
                        endDate.setHours(23, 59, 59, 999);
                        matchesEndDate = eventDate <= endDate;
                    }

                    return matchesText && matchesStartDate && matchesEndDate;
                });
            }
        }

        setFilteredEvents(newFilteredEvents);
        setEvents(newFilteredEvents.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    // Add this useMemo hook to compute unique venues
    const venues = useMemo(() => {
        const uniqueVenues = [...new Set(allEvents.map(event => event.Venue))];
        return uniqueVenues.sort((a, b) => a.localeCompare(b));
    }, [allEvents]);

    const handleClearFilter = () => {
        setSelectedVenue('all');
        localStorage.setItem('selectedVenue', 'all');
        setSearchParams({});
        setCurrentPage(1);
        // Instead of setting eventId to null here, we'll update the URL
        if (eventId) {
            // Remove the id parameter from the URL
            const newUrl = window.location.pathname;
            window.history.pushState({}, '', newUrl);
            // Trigger a re-render to clear the eventId
            window.dispatchEvent(new Event('popstate'));
        }
    };

    const isFilterActive = selectedVenue !== 'all' || eventId || (searchParams && Object.values(searchParams).some(value => value !== null && value !== ''));

    return (
        <div className="event-list-container">
         <h1 className="visually-hidden">Local Concert Event List Philadelphia</h1>
            <VenueFilter 
                onVenueChange={handleVenueChange}
                isFilterActive={isFilterActive}
                onClearFilter={handleClearFilter}
                user={user}
                selectedVenue={eventId ? 'all' : selectedVenue}
                venues={venues}
                disabled={!!eventId}
            />
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
                <>
                    {!eventId && filteredEvents.length > ITEMS_PER_PAGE && (
                        <PaginationControls 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            position="top"
                        />
                    )}
                    <EventListDisplay 
                        events={events}
                        expandedEventId={expandedEventId}
                        onExpand={handleEventExpand}
                        onSaveToggle={handleSaveToggle}
                        user={user}
                        savedEventIds={savedEventIds}
                    />
                    {!eventId && filteredEvents.length > ITEMS_PER_PAGE && (
                        <PaginationControls 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            position="bottom"
                        />
                    )}
                    {filteredEvents.length === 0 && <NoEventsFound />}
                </>
            )}
        </div>
    );
};

export default EventList;

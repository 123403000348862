import React from 'react';
import './VenueFilter.css';

const VenueFilter = ({ onVenueChange, isFilterActive, onClearFilter, user, selectedVenue, venues, disabled }) => {
    // Helper function to sort venues ignoring "The"
    const sortVenues = (venues) => {
        return [...venues].sort((a, b) => {
            const nameA = a.replace(/^the\s+/i, '').toLowerCase();
            const nameB = b.replace(/^the\s+/i, '').toLowerCase();
            return nameA.localeCompare(nameB);
        });
    };

    const sortedVenues = sortVenues(venues);

    return (
        <div className="venue-filter-container">
            <div className="venue-filter">
                <select 
                    value={selectedVenue} 
                    onChange={(e) => onVenueChange(e.target.value)}
                    disabled={disabled}
                    className={disabled ? 'disabled' : ''}
                >
                    <option value="all">All Events</option>
                    <option value="new-today">Added Today!</option>
                    <option value="new-week">Added This Week!</option>
                    <option value="saved" disabled={!user}>
                        {user ? 'Saved Events' : 'Log in for Saved Events'}
                    </option>
                    <option value="" disabled>──────────</option>
                    {sortedVenues.map((venue) => (
                        <option key={venue} value={venue}>
                            {venue}
                        </option>
                    ))}
                </select>
            </div>
            {isFilterActive && (
                <div className="clear-search-container">
                    <button onClick={onClearFilter} className="clear-search-button">
                        Clear Filter
                    </button>
                </div>
            )}
        </div>
    );
};

export default VenueFilter;
